import * as _ from 'lodash';
import * as React from 'react';
import moment = require('moment');
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popover } from 'antd';

import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import 'firebase/firestore';

import mapka from './mapka.png';

const Comp = styled.div`
    a {
        color: black;
    }
    .card {
        position: absolute;
        display: inline-block;
        background-color: yellow;
        border-radius: 6px;
        padding: 5px;
        font-size: 90%;
        transform: translate(-50%, -50%);
        border: 2px solid;
    }
    .no-menu {
        background-color: lightgrey;
        border: 0px;
    }
`;

export function IndexPage(props) {
    const actDate = moment().format('YYYY-MM-DD');
    const [menus, menusLoading, menusError] = useCollectionData(
        firebase.app().firestore().collection('menu').where('date', '==', actDate),
        {idField: '_id'}
    );
    const [places, placesLoading, placesError] = useCollectionData(
        firebase.app().firestore().collection('places'),
        {idField: '_id'}
    );
    if (menus == null || places == null || places.length === 0) {
        return null;
    }
    const places_render = places.map( (place) => {
        const menu = _.find(menus, {'place': place['_id']});
        const position = {
            left: place['position']['x'],
            top: place['position']['y'],
        };
        const items = place['items'] || _.get(menu, ['items']);
        if (items == null) {
            return (
                <div key={place['_id']} className='card no-menu' style={position}>
                    <a href={place['url']}>
                        {place['name']}
                    </a>
                </div>
            );
        }
        const shortMenu = [];
        const fullMenu = items.map( (item, itemIdx) => {
            if (item['short'] != null) {
                shortMenu.push(<div key={itemIdx}>{item['short']}</div>);
            }
            return <p key={itemIdx}>{item['orig']}</p>;
        });
        const tooltip = (
            <div>
                {fullMenu}
            </div>
        );
        return (
            <Popover content={tooltip} key={place['_id']} title={place['name']}>
                <div className='card' style={position}>
                    <a href={place['url']}>
                        {shortMenu.length ? shortMenu : place['name']}
                    </a>
                </div>
            </Popover>
        );
    });
    return (
        <Comp>
            <img src={mapka} />
            {places_render}
        </Comp>
    );
}

export default IndexPage;
