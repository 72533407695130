import * as _ from 'lodash';
import * as React from 'react';
import moment = require('moment');
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popover, Button } from 'antd';

import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import 'firebase/firestore';


export function DownloadPage(props) {
    const [menus, menusLoading, menusError] = useCollectionData(
        firebase.app().firestore().collection('menu'),
        {idField: '_id'}
    );
    console.log(JSON.stringify(menus));

    return (<div>A je to</div>);
}

export default DownloadPage;
