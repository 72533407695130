import * as React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import * as firebase from 'firebase/app';

import './index.scss';
import 'antd/dist/antd.css';

import HanddrawnPage from './routes/handdrawn/index';
import IngestPage from './routes/ingest';
import IndexPage from './routes/index';
import DownloadPage from './routes/download';

const config = {
    apiKey: 'AIzaSyAoacDA3xIeN3lWKyBYp9HRFF5yB-zGTgE',
    authDomain: 'acomaj-b06bc.firebaseapp.com',
    databaseURL: 'https://acomaj-b06bc.firebaseio.com',
    projectId: 'acomaj-b06bc',
    storageBucket: 'acomaj-b06bc.appspot.com',
    messagingSenderId: '534553197776',
    appId: '1:534553197776:web:8362548673489bbd5a32d5',
    measurementId: 'G-B24CRHBNBC'
};
firebase.initializeApp(config);

render((
    <Router>
        <Route path='/' exact={true} component={IndexPage} />
        <Route path='/ingest' component={IngestPage} />
        <Route path='/handdrawn' component={HanddrawnPage} />
        <Route path='/download' component={DownloadPage} />
    </Router>
), document.getElementById('root'));
