import warning from 'warning';
let warned = {};
export function resetWarned() {
    warned = {};
}
export default (valid, component, message) => {
    if (!valid && !warned[message]) {
        warning(false, `[antd: ${component}] ${message}`);
        warned[message] = true;
    }
};
