var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import omit from 'omit.js';
import * as React from 'react';
import { polyfill } from 'react-lifecycles-compat';
import RcMentions from 'rc-mentions';
import Spin from '../spin';
import { ConfigConsumer } from '../config-provider';
const Option = RcMentions.Option;
function loadingFilterOption() {
    return true;
}
class Mentions extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            focused: false,
        };
        this.onFocus = (...args) => {
            const { onFocus } = this.props;
            if (onFocus) {
                onFocus(...args);
            }
            this.setState({
                focused: true,
            });
        };
        this.onBlur = (...args) => {
            const { onBlur } = this.props;
            if (onBlur) {
                onBlur(...args);
            }
            this.setState({
                focused: false,
            });
        };
        this.getOptions = () => {
            const { children, loading } = this.props;
            if (loading) {
                return (<Option value={'ANTD_SEARCHING'} disabled>
          <Spin size="small"/>
        </Option>);
            }
            return children;
        };
        this.getFilterOption = () => {
            const { filterOption, loading } = this.props;
            if (loading) {
                return loadingFilterOption;
            }
            return filterOption;
        };
        this.renderMentions = ({ getPrefixCls, renderEmpty }) => {
            const { focused } = this.state;
            const _a = this.props, { prefixCls: customizePrefixCls, className, disabled } = _a, restProps = __rest(_a, ["prefixCls", "className", "disabled"]);
            const prefixCls = getPrefixCls('mentions', customizePrefixCls);
            const mentionsProps = omit(restProps, ['loading']);
            const mergedClassName = classNames(className, {
                [`${prefixCls}-disabled`]: disabled,
                [`${prefixCls}-focused`]: focused,
            });
            return (<RcMentions prefixCls={prefixCls} notFoundContent={this.getNotFoundContent(renderEmpty)} className={mergedClassName} disabled={disabled} {...mentionsProps} filterOption={this.getFilterOption()} onFocus={this.onFocus} onBlur={this.onBlur}>
        {this.getOptions()}
      </RcMentions>);
        };
    }
    getNotFoundContent(renderEmpty) {
        const { notFoundContent } = this.props;
        if (notFoundContent !== undefined) {
            return notFoundContent;
        }
        return renderEmpty('Select');
    }
    render() {
        return <ConfigConsumer>{this.renderMentions}</ConfigConsumer>;
    }
}
Mentions.Option = Option;
Mentions.getMentions = (value = '', config) => {
    const { prefix = '@', split = ' ' } = config || {};
    const prefixList = Array.isArray(prefix) ? prefix : [prefix];
    return value
        .split(split)
        .map((str = '') => {
        let hitPrefix = null;
        prefixList.some(prefixStr => {
            const startStr = str.slice(0, prefixStr.length);
            if (startStr === prefixStr) {
                hitPrefix = prefixStr;
                return true;
            }
            return false;
        });
        if (hitPrefix !== null) {
            return {
                prefix: hitPrefix,
                value: str.slice(hitPrefix.length),
            };
        }
        return null;
    })
        .filter((entity) => !!entity && !!entity.value);
};
polyfill(Mentions);
export default Mentions;
