import * as _ from 'lodash';
import * as React from 'react';
import moment = require('moment');
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popover, Button } from 'antd';

import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import 'firebase/firestore';

const Comp = styled.div`
    a {
        color: black;
    }
    table {
        border-collapse: collapse;
    }
    table, th, td {
        border: 1px solid grey;
    }
`;

export function IngestPage(props) {
    const actDate = moment().format('YYYY-MM-DD');
    const [menus, menusLoading, menusError] = useCollectionData(
        firebase.app().firestore().collection('menu').where('date', '==', actDate),
        {idField: '_id'}
    );
    const [places, placesLoading, placesError] = useCollectionData(
        firebase.app().firestore().collection('places'),
        {idField: '_id'}
    );
    if (places == null || places.length === 0) {
        return null;
    }
    if (menus == null || menus.length === 0) {
        const ingest = () => {
            const toIngest = ['presto', 'khomfi', 'frida', 'peters-burger', 'u-tunelu', 'diego', 'amboseli',
                              'salanda', 'modry-beranek'];
            toIngest.forEach( (place) => {
                firebase.app().firestore().collection('places').doc(place).collection('ingest').doc(actDate).set({'do': 'now'});
            });
        };
        return (
            <div>
                Není naingestováno <Button onClick={ingest}>INGESTOVAT {actDate}</Button>
            </div>
        );
    }
    const menusRender = menus.map( (menu) => {
        const header = <h2>{menu['place']}</h2>;
        const items = _.get(menu, ['items']);
        if (items == null) {
            return (
                <div key={menu['_id']}>
                    {header}
                </div>
            );
        }
        const editor = (itemIdx) => () => {
            const updateVal = _.cloneDeep(items);
            const update = window.prompt('Nová hodnota', updateVal[itemIdx]['short']);
            updateVal[itemIdx]['short'] = update;
            firebase.app().firestore().collection('menu').doc(menu['_id']).update({'items': updateVal});
        };
        const fullMenu = items.map( (item, itemIdx) => {
            const itemShort = <Button onClick={editor(itemIdx)}>{item['short'] || '-------'}</Button>;
            return <tr key={itemIdx}><td style={{width: 200}}>{itemShort}</td><td>{item['orig']}</td></tr>;
        });
        return (
            <div key={menu['_id']}>
                {header}
                <table><tbody>{fullMenu}</tbody></table>
            </div>
        );
    });
    return (
        <Comp>
            {menusRender}
        </Comp>
    );
}

export default IngestPage;
