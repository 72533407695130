import * as _ from 'lodash';


export const LEFT = 14.443330764770506;
export const RIGHT = 14.456183910369873;
const BOTTOM = 50.08965365858419;
const TOP = 50.095050014126095;
const HEIGHT_MULTIPLIER = 1.1;

function findClosest(rec1, rec2) {
    let x1, x2, y1, y2;
    let w, h;
    if (rec1.x1 > rec2.x1) {
        x1 = rec2.x1; w = rec2.x2 - rec2.x1; x2 = rec1.x1;
    } else {
        x1 = rec1.x1; w = rec1.x2 - rec1.x1; x2 = rec2.x1;
    }
    if (rec1.y1 > rec2.y1) {
        y1 = rec2.y1; h = rec2.y2 - rec2.y1; y2 = rec1.y1;
    } else {
        y1 = rec1.y1; h = rec1.y2 - rec1.y1; y2 = rec2.y1;
    }
    const a = Math.max(0, x2 - x1 - w);
    const b = Math.max(0, y2 - y1 - h);
    return Math.sqrt(a * a + b * b);
}

export function transformRaw(pnt, multiplier): [number, number] {
    const offset = (TOP - BOTTOM) * multiplier * HEIGHT_MULTIPLIER;
    return [(pnt[0] - LEFT) * multiplier, offset - (pnt[1] - BOTTOM) * multiplier * HEIGHT_MULTIPLIER];
}

export function calcRect(card, grPos) {
    const width = card.content.reduce((total, contentItem) => Math.max(total, contentItem.length), 0) * 5.5 + 4;
    const height = card.content.length * 16.5 + 4;
    card['act'] = { x1: grPos[0] - width / 2, x2: grPos[0] + width / 2, y1: grPos[1] - height / 2, y2: grPos[1] + height / 2 };
}

class SingleCard {
    card = null;

    calculateRect(actMulti: number) {
        calcRect(this.card, transformRaw([this.card.geo.longitude, this.card.geo.latitude], actMulti));
    }
    vectorize(multi) {}
}

class MultiCard extends SingleCard {
    cards = [];
    offsets = [];
    geo = [0, 0];

    addCards(other: SingleCard) {
        if (other instanceof MultiCard) {
            this.cards.push(other.cards);
        } else {
            this.cards.push(other.card);
        }
    }
    calculateRect(actMulti: number) {
        this.cards.forEach((card) => calcRect(card, actMulti));
        const screenPos = transformRaw(this.geo, actMulti);
        for (let idx = 0; idx < this.cards.length; ++idx) {
            calcRect(this.cards[idx], [this.offsets[idx][0] + screenPos[0], this.offsets[idx][1] + screenPos[1]]);
        }
    }
    vectorize(multi) {
        this.geo = this.cards.reduce((total, card) => [total[0] + card.geo.longitude, total[1] + card.geo.latitude], [0, 0]);
        this.geo[0] /= this.cards.length;
        this.geo[1] /= this.cards.length;
        const screenPos = transformRaw(this.geo, multi);
        this.offsets = this.cards.map( (card) => {
            return [(card['act']['x1'] + card['act']['x2']) / 2 - screenPos[0], (card['act']['y1'] + card['act']['y1']) / 2 - screenPos[1]];
        });
    }
}

function isClose(oneCard: SingleCard, secondCard: SingleCard) {
    const oneSet = oneCard instanceof MultiCard ? oneCard.cards : [oneCard.card];
    const secondSet = secondCard instanceof MultiCard ? secondCard.cards : [secondCard.card];
    for (let oneIdx = 0; oneIdx < oneSet.length; ++oneIdx) {
        for (let secondIdx = 0; secondIdx < secondSet.length; ++secondIdx) {
            if (findClosest(oneSet[oneIdx]['act'], secondSet[secondIdx]['act']) < 10) {
                return true;
            }
        }
    }
    return false;
}

export class Layout extends SingleCard {
    basicMultiplier = 0;
    private _width = 0;

    cards = [];

    get width() {
        return this._width;
    }
    set width(val: number) {
        this._width = val;
        this.basicMultiplier = val / (RIGHT - LEFT);
    }

    transform(pnt): [number, number] {
        return transformRaw(pnt, this.basicMultiplier);
    }

    alignCards(cards) {
        this.cards = cards;
        let actWidth = 4000;
        let newCards = this.getWrappedCards();
        while (actWidth > this._width + 5) {
            newCards = this._singleStep(newCards, actWidth);
            actWidth -= 10;
        }
        this._singleStep(newCards, this._width);
        this.cards.map((card) => {
            card['screenPos'] = {
                left: (card['act']['x1'] + card['act']['x2']) / 2,
                top: (card['act']['y1'] + card['act']['y1']) / 2,
            };
        });
    }
    getWrappedCards() {
        return this.cards.map((card) => {
            const theCard = new SingleCard();
            theCard.card = card;
            return theCard;
        });
    }
    private _singleStep(cards: SingleCard[], actWidth) {
        console.log(actWidth);
        const actMulti = actWidth / (RIGHT - LEFT);
        cards.forEach((card) => {
            card.calculateRect(actMulti);
        });
        const newCards = this.getWrappedCards();
        while (true) {
            let merged = false;
            for (let testCardIdx = 0; testCardIdx < newCards.length; ++testCardIdx) {
                const testCard = newCards[testCardIdx];
                for (let otherCardIdx = testCardIdx + 1; otherCardIdx < newCards.length; ++otherCardIdx) {
                    const otherCard = newCards[otherCardIdx];
                    if (isClose(testCard, otherCard)) {
                        if (!(testCard instanceof MultiCard)) {
                            const newMultiCard = new MultiCard();
                            newMultiCard.addCards(testCard);
                            newCards[testCardIdx] = newMultiCard;
                        }
                        newCards[testCardIdx].addCards(otherCard);
                        newCards.splice(otherCardIdx);
                        merged = true;
                        break;
                    }
                }
                if (merged) {
                    break;
                }
            }
            if (merged === false) {
                break;
            }
        }
        console.log(newCards.length);
        newCards.forEach( (card) => {
            card.vectorize(actMulti);
        });
        return newCards;
    }
}
