import * as _ from 'lodash';

import { transformRaw, calcRect, RIGHT, LEFT } from './layout';

export class Layout {
    basicMultiplier = 0;
    cards = [];
    _width: number;

    set width(val: number) {
        this._width = val;
        this.basicMultiplier = val / (RIGHT - LEFT);
    }

    transform(pnt): [number, number] {
        return transformRaw(pnt, this.basicMultiplier);
    }

    alignCards(cards) {
        console.log(cards);
        cards.forEach((card) => {
            const geoPos = card['geo'];
            const grPos = this.transform([geoPos.longitude, geoPos.latitude]);
            calcRect(card, grPos);
        });
        alignTwoCards(cards, 'diego', 'podolka', 'move-down');
        alignTwoCards(cards, 'peters-burger', 'u-tunelu', 'move-down');
        alignTwoCards(cards, 'modry-beranek', 'salanda', 'align-horizontal');
        alignTwoCards(cards, 'modry-beranek', 'gastro', 'move-left');

        alignTwoCards(cards, 'amboseli', 'frida', 'align-horizontal');
        alignTwoCards(cards, 'presto', 'khomfi', 'align-horizontal');
        alignTwoCards(cards, 'amboseli', 'presto', 'move-down');
        alignTwoCards(cards, 'amboseli', 'khomfi', 'move-down');

        cards.forEach((card) => {
            card['screenPos'] = {left: (card['act']['x1'] + card['act']['x2']) / 2,
                                 top: 40 + (card['act']['y1'] + card['act']['y2']) / 2};
        });
    }
}

function alignTwoCards(cards, firstName, secondName, strategy) {
    const firstCard = _.find(cards, {'_id': firstName});
    const secondCard = _.find(cards, {'_id': secondName});
    const overlap = calcOverlap(firstCard['act'], secondCard['act']);
    if (overlap.y > 0 && strategy === 'move-down') {
        secondCard['act']['y1'] += overlap.y + 10;
        secondCard['act']['y2'] += overlap.y + 10;
    } else if (overlap.x > 0 && strategy === 'move-left') {
        secondCard['act']['x1'] -= overlap.y + 10;
        secondCard['act']['x2'] -= overlap.y + 10;
    } else if (overlap.x > 0 && strategy === 'align-horizontal') {
        const shift = overlap.x / 2 + 5;
        firstCard['act']['x1'] -= shift;
        firstCard['act']['x2'] -= shift;
        secondCard['act']['x1'] += shift;
        secondCard['act']['x2'] += shift;
    }

}

function calcOverlap(a, b) {
    let x = 0, y = 0;
    if (b.x2 < a.x1 || a.x2 < b.x1) {
        // no overlap at x
    } else {
        const startX = Math.max(a.x1, b.x1);
        const endX = Math.min(a.x2, b.x2);
        x = endX - startX;
    }
    if (b.y2 < a.y1 || a.y2 < b.y1) {
        // no overlap at y
    } else {
        const startY = Math.max(a.y1, b.y1);
        const endY = Math.min(a.y2, b.y2);
        y = endY - startY;
    }
    return x > 0 && y > 0 ? {x, y} : {x: 0, y: 0};
}
