import * as _ from 'lodash';
import * as React from 'react';
import moment = require('moment');
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popover, Button } from 'antd';

import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'firebase/auth';
import 'firebase/firestore';

import { useWindowSize } from './resize-hook';
import karlin from './karlin.json';
import { Layout } from './layout';
import { Layout as OldLayout } from './old-layout';
import { Layout as KarlinLayout } from './karlin-layout';

const Page = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .title {
        flex-grow: 0;
        flex-shrink: 1;
        width: 100%;
        background-color: lightgrey;
        text-align: center;
        padding: 10px 0;
        font-size: 110%;
        font-weight: bold;
        height: 40px;
        span {
            background-color: yellow;
            color: red;
            padding: 0 10px;
        }
    }
    svg {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .card {
        position: absolute;
        display: inline-block;
        background-color: yellow;
        border-radius: 6px;
        padding: 2px;
        font-size: 80%;
        transform: translate(-50%, -50%);
        border: 1px solid;

        a {
            color: black;
        }
    }
    .no-menu {
        background-color: lightgrey;
        border: 0px;
    }

    .footer {
        flex-grow: 0;
        flex-shrink: 1;
        text-align: center;
        background-color: lightgrey;
        color: white;
        padding: 3px 0;
        a {
            color: white;
        }
    }
`;

export function IndexPage(props) {
    const windowSize = useWindowSize();
    const actDate = moment().format('YYYY-MM-DD');
    const [menus, menusLoading, menusError] = useCollectionData(
        firebase.app().firestore().collection('menu').where('date', '==', actDate),
        {idField: '_id'}
    );
    const [places, placesLoading, placesError] = useCollectionData(
        firebase.app().firestore().collection('places'),
        {idField: '_id'}
    );
    const [user, userLOading, userError] = useAuthState(firebase.app().auth());
    if (menus == null || places == null || places.length === 0) {
        return null;
    }
    const layout = new KarlinLayout();
    layout.width = windowSize.width;
    const geojson = [];
    karlin['features'].forEach((feature, featureIdx) => {
        const coordinates = feature['geometry']['coordinates'];
        let strokeWidth = 1;
        switch (feature['properties']['highway']) {
            case 'residential': strokeWidth = 10; break;
            case 'tertiary_link': strokeWidth = 1; break;
            case 'primary': strokeWidth = 20; break;
            case 'tertiary': strokeWidth = 20; break;
            case 'track': strokeWidth = 1; break;
            case 'living_street': strokeWidth = 1; break;
            case 'cycleway': strokeWidth = 1; break;
            case 'construction': strokeWidth = 1; break;
        }
        for (let i = 0; i < coordinates.length - 1; ++i) {
            const from = layout.transform(coordinates[i]);
            const to = layout.transform(coordinates[i + 1]);
            geojson.push(
                <line
                    key={featureIdx.toString() + '-' + i.toString()}
                    x1={from[0]}
                    y1={from[1]}
                    x2={to[0]}
                    y2={to[1]}
                    stroke='lightgrey'
                    strokeWidth={strokeWidth}
                />
            );
        }
    });
    const places_cards = places.map((place) => {
        if (place['geopoint'] == null) {
            return;
        }
        const menu = _.find(menus, {'place': place['_id']});
        const dt = new Date();
        const items = place['items'] || _.get(menu, ['items']) || _.get(place, ['week', dt.getDay() - 1]);
        const result = {
            _id: place['_id'],
            url: place['url'],
            name: place['name'],

            geo: place['geopoint'],
            content: [place['name'].replace(/ /g, '\u00A0')],
            tooltip: null,

            screenPos: null,
        };
        if (items == null) {
            return result;
        }
        const content = [];
        result.tooltip = items.map( (item, itemIdx) => {
            if (item['short'] != null) {
                content.push(item['short'].replace(/ /g, '\u00A0'));
            }
            return <p key={itemIdx}>{item['orig']}</p>;
        });
        if (content.length > 0) {
            result.content = content;
        }
        return result;
    });
    layout.alignCards(places_cards);
    const places_render = places_cards.map( (place) => {
        const link = (
            <a href={place['url']}>
                {place.content.map((item, itemIdx) => (<div key={itemIdx}>{item}</div>))}
            </a>
        );

        if (place.tooltip == null) {
            return (
                <div key={place['_id']} className='card no-menu' style={place.screenPos}>{link}</div>
            );
        }
        return (
            <Popover key={place['name']} content={<div>{place.tooltip}</div>}  title={place['name']}>
                <div className='card' style={place.screenPos}>{link}</div>
            </Popover>
        );
    });
    const onLogin = () => {
        firebase.app().auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    };
    const onLogout = () => {
        firebase.app().auth().signOut();
    };
    const userRender = user ? <span onClick={onLogout}>čus {user.email}</span> : null;
    return (
        <Page>
            <h1 className='title'>
                Nedatovaný rozhovor v nejmenované karlínské firmě kolem 11:30 "Co oběd dneska?"
                <span onClick={onLogin}>"A co maj?"</span> A hvězda se zrodila :) {userRender}
            </h1>
            <svg>
                {geojson}
            </svg>
            {places_render}
            <div className='footer'>
                Jídla jsou ze stránky v prokliku. Mapový podklad je z
                &nbsp;<a href='https://www.openstreetmap.org'>https://www.openstreetmap.org</a>
                &nbsp; Zkráceniny názvů jídel vymýšlí mladá neuronka, je ještě nezkušená, tak buďte shovívavý.
                &nbsp;|&nbsp; <a href='/handdrawn'>v1</a>
                &nbsp;|&nbsp; <a href='https://bitbucket.org/tomas-rokos/acomaj'>git</a>
            </div>
        </Page>
    );
}

export default IndexPage;
